import { type IUserInfo } from "../types/checkout";
import { invalidPhone } from "../utils/validation";
import {
  REGEX,
  REGEX_VALIDATION_STRINGS,
  VALIDATION_STRINGS,
} from "@/constants/validation";

export const loginValidator = (values: {
  identifier: string;
  password: string;
  secret: string;
}) => {
  const errors: any = {};
  if (!values.identifier) {
    errors.identifier = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.password) {
    errors.password = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.secret) {
    errors.secret = VALIDATION_STRINGS.REQUIRED;
  }
  return errors;
};

export const editUserValidator = (values: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  current_password: string;
  password_confirmation: string;
  phone: string;
  zip_code: string;
}) => {
  const errors: any = {};

  if (!values.first_name) {
    errors.first_name = VALIDATION_STRINGS.REQUIRED;
  }

  if (!values.last_name) {
    errors.last_name = VALIDATION_STRINGS.REQUIRED;
  }

  if (!values.email) {
    errors.email = VALIDATION_STRINGS.REQUIRED;
  }

  if (values.email && !REGEX.EMAIL.test(values.email)) {
    errors.email = REGEX_VALIDATION_STRINGS.EMAIL;
  }

  if (!values.phone) {
    errors.phone = VALIDATION_STRINGS.REQUIRED;
  }

  if (values.phone && invalidPhone(values.phone)) {
    errors.phone = VALIDATION_STRINGS.PHONE;
  }

  if (!values.zip_code) {
    errors.zip_code = VALIDATION_STRINGS.REQUIRED;
  }

  if (
    values.zip_code &&
    !REGEX.ZIP_CODE_USA_AND_POSTAL_CODE_CANADA.test(values.zip_code)
  ) {
    errors.zip_code =
      REGEX_VALIDATION_STRINGS.ZIP_CODE_USA_AND_POSTAL_CODE_CANADA;
  }

  if (values.password && values.password.length < 8) {
    errors.password = VALIDATION_STRINGS.PASSWORD_LENGTH;
  }
  if (values.password && values.password_confirmation !== values.password) {
    errors.password = VALIDATION_STRINGS.PASSWORD_MISMATCH;
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.password) {
    errors.password = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.current_password) {
    errors.current_password = VALIDATION_STRINGS.REQUIRED;
  }
  return errors;
};

export const guestUserValidator = (values: IUserInfo) => {
  const errors: any = {};

  if (!values.first_name) {
    errors.first_name = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.last_name) {
    errors.last_name = VALIDATION_STRINGS.REQUIRED;
  }
  if (!values.email) {
    errors.email = VALIDATION_STRINGS.REQUIRED;
  }
  if (values.email && !REGEX.EMAIL.test(values.email)) {
    errors.email = REGEX_VALIDATION_STRINGS.EMAIL;
  }
  if (!values.phone) {
    errors.phone = VALIDATION_STRINGS.REQUIRED;
  }
  if (values.phone && invalidPhone(values.phone)) {
    errors.phone = VALIDATION_STRINGS.PHONE;
  }
  return errors;
};
