import Router from "next/router";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { SocialButtons } from "../socialButtons/index";
import LoginForm from "./form";
import ReCaptcha, {
  type ReCaptchaRef,
} from "@/components/uielements/recaptcha";
import { CAPTCHA_CONFIG } from "@/constants/authentication";
import authActions from "@/redux/auth/actions";
import { type RootState } from "@/types/app";
import { type IUserLogin } from "@/types/authentication";
import { isAndroidShell } from "@/utils/android";
import { parseReferrer } from "@/utils/global";

interface State {
  formValues: IUserLogin | null;
  recaptchaRef: ReCaptchaRef | undefined;
}

class LoginComponent extends Component<ReduxProps, State> {
  state = {
    formValues: null,
    recaptchaRef: undefined,
  };

  loginFactory = (formValues: IUserLogin, captchaResponse?: string | null) => {
    // Respect the referrer ONLY if we're on the login page
    const referrer = parseReferrer(Router);

    this.props.onLogin(
      Object.assign({}, formValues, {
        [CAPTCHA_CONFIG.FIELD_NAME]: captchaResponse,
      }),
      referrer
    );

    // If we're using a captcha, reset the captcha
    if (captchaResponse) {
      /** @TODO ensure that the ref is defined. */
      // @ts-expect-error
      this.state.recaptchaRef?.current.reset();
    }
  };

  checkReCaptcha = async (formValues: IUserLogin) => {
    const { recaptchaRef } = this.state;
    /** @TODO ensure that the ref is defined. */
    // @ts-expect-error
    if (recaptchaRef?.current) {
      /** @TODO ensure that the ref is defined. */
      // @ts-expect-error
      const captchaResponse: string = await recaptchaRef.current.executeAsync();
      this.loginFactory(formValues, captchaResponse);
    } else {
      this.loginFactory(formValues);
    }
  };

  render() {
    const { loading } = this.props;

    return (
      <>
        {/** @TODO ensure that UserInfo is defined from the callback. */}
        {/** @ts-expect-error */}
        <LoginForm onLogin={this.checkReCaptcha} loading={loading} />
        {/* Social Buttons */}
        {/** @ts-expect-error */}
        {!isAndroidShell() && <SocialButtons onLogin={this.loginFactory} />}

        {/* Conditional ReCaptcha */}
        <ReCaptcha
          onRef={(recaptchaRef) => this.setState({ recaptchaRef })}
          forwardRef={this.state.recaptchaRef}
          action={CAPTCHA_CONFIG.API_ACTIONS.LOGIN}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loading: state.app.auth.loading,
});

const mapDispatchToProps = {
  onLogin: authActions.login,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(LoginComponent);
