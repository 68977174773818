import { Component } from "react";
import SocialLogin from "react-social-login";
import { SocialButton } from "./styles";

interface Props {
  children: React.ReactNode;
  triggerLogin: () => void;
}

class Button extends Component<Props> {
  render() {
    const { children, triggerLogin } = this.props;

    return (
      <SocialButton onClick={triggerLogin} {...this.props}>
        {children}
      </SocialButton>
    );
  }
}

export default SocialLogin(Button);
