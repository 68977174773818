import { useRouter } from "next/router";
import { FacebookLoginButton } from "react-social-login-buttons";
import SocialButton from "./SocialButton";
import { AppleLoginButton } from "./appleLogin";
import {
  Circle,
  OrBlock,
  SocialBox,
  StyledSocialButtonContainer,
} from "./styles";
import { StyledHr } from "@/components/uielements/hr";
import { DEFAULT_SOCIAL_EXTRA_PAYLOAD } from "@/constants/authentication";
import { K_ANALYTICS_EVENTS, LOG_EVENTS } from "@/constants/events";
import { useSelector } from "@/redux";
import { type IUserLogin } from "@/types/authentication";
import * as ErrorReporter from "@/utils/errorReporter";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";

interface Props {
  onLogin: (values?: IUserLogin) => void;
}

export function SocialButtons({ onLogin }: Props) {
  const route = useRouter();
  const { facebook_app_id, apple_service_id } = useSelector(
    (state) => state.app.cmsConfig.webConfig.integrations
  );

  const handleGoogleAndFacebookLogin = (user: any) => {
    // Google expects idToken while Facebook expects accessToken
    const secret =
      user._provider === "google"
        ? user._token.idToken
        : user._token.accessToken;

    const socialPayload = {
      identifier: user._profile.email,
      provider: user._provider,
      secret,
    };

    const payload = {
      ...socialPayload,
      ...DEFAULT_SOCIAL_EXTRA_PAYLOAD,
    };

    onLogin(payload);
  };

  const handleSocialLoginFailure = (err: unknown) => {
    ErrorReporter.captureException(err);
    console.error(err);
  };
  const buttonStyle = {
    fontSize: "16.5px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "6px",
    fontFamily: "Helvetica",
  };

  if (!facebook_app_id && !apple_service_id) {
    return null;
  }

  return (
    <>
      <SocialBox>
        <OrBlock>
          <StyledHr />
          <Circle>OR</Circle>
        </OrBlock>

        <StyledSocialButtonContainer>
          {/* Facebook */}
          {facebook_app_id ? (
            <SocialButton
              provider="facebook"
              appId={facebook_app_id}
              onLoginSuccess={handleGoogleAndFacebookLogin}
              onLoginFailure={handleSocialLoginFailure}
              scope="email"
            >
              <FacebookLoginButton
                style={buttonStyle}
                text="Sign in with Facebook"
                onClick={() => {
                  // KA event
                  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
                    name: LOG_EVENTS.FACEBOOK_LOGIN_CLICK,
                    details: route.route,
                  });
                }}
              />
            </SocialButton>
          ) : null}

          {/* Apple */}
          {apple_service_id ? (
            <AppleLoginButton
              onLogin={onLogin}
              onLoginFailure={handleSocialLoginFailure}
            />
          ) : null}
        </StyledSocialButtonContainer>
      </SocialBox>
    </>
  );
}
