import { Component } from "react";
import { Field, type InjectedFormProps, reduxForm } from "redux-form";
import { loginValidator as validate } from "@/components/../validators/authValidators";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { ReduxFormField } from "@/components/uielements/form-fields";
import {
  StyledFormField,
  StyledGenericForm,
  StyledGenericFieldset,
  StyledFormGroup,
} from "@/components/uielements/form-fields/styles";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD } from "@/constants/authentication";
import { type IUserLogin } from "@/types/authentication";

interface Props {
  loading: boolean;
  onLogin: (values?: IUserLogin, referrer?: string | boolean | null) => void;
  referrer?: string | boolean | null;
}

class LoginForm extends Component<Props & InjectedFormProps<Props>> {
  render() {
    const { handleSubmit, onLogin, loading } = this.props;
    const extraPayload = DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD;
    const accessor = !loading ? "login.cta" : "login.cta_loading";

    return (
      <StyledGenericForm
        onSubmit={handleSubmit((values) =>
          onLogin(Object.assign(values, extraPayload))
        )}
      >
        <StyledGenericFieldset>
          <legend>Login form</legend>
          <StyledFormGroup>
            {/* Login Inputs */}
            <StyledFormField key={`login-form-field-identifier`}>
              <Field
                key="identifier"
                name="identifier"
                type="email"
                placeholder="Email"
                autoComplete="email"
                component={ReduxFormField}
                autoFocus={false}
              />
            </StyledFormField>
            <StyledFormField key={`login-form-field-password`}>
              <Field
                key="secret"
                name="secret"
                type="password"
                placeholder="Password"
                autoComplete="off"
                component={ReduxFormField}
                autoFocus={false}
              />
            </StyledFormField>
          </StyledFormGroup>
        </StyledGenericFieldset>

        {/* Submit Button */}
        <StyledPrimaryButton
          data-testid="signin-button"
          submitting={loading}
          type="submit"
          style={{ width: "100%" }}
          buttonTheme="secondary"
        >
          <StringAccessor accessor={accessor} />
        </StyledPrimaryButton>
      </StyledGenericForm>
    );
  }
}

export default reduxForm<object, Props>({
  form: "login",
  validate,
})(LoginForm as any);
